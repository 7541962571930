import React from "react"
import { FaCircle } from "@react-icons/all-files/fa/FaCircle"

import PropTypes from "prop-types"

const CircleIcon = ({ name, circleSize, iconSize, left, top, circleColor }) => (
  <div className="circle-icon">
    <div style={{ position: "relative" }}>
      <FaCircle size={circleSize} style={{ color: `${circleColor}` }} />
      <img
        src={`/images/icons/${name}.svg`}
        alt=""
        width={iconSize}
        style={{ position: "absolute", left, top }}
      />
    </div>
  </div>
)

CircleIcon.propTypes = {
  name: PropTypes.string,
  circleSize: PropTypes.string,
  iconSize: PropTypes.string,
  circleColor: PropTypes.string,
  left: PropTypes.string,
  top: PropTypes.string,
}
CircleIcon.defaultProps = {
  name: "icons-engineering",
  circleSize: "60px",
  iconSize: "90%",
  circleColor: "blue-lighter",
  left: "-10px",
  top: "10px",
}

export default CircleIcon
